h1 {
  color: green;
}

/* styling sidebar */
.leftsidehoverbar a {
  background-color: green;
  position: fixed;
  font-size: 22px;
  text-decoration: none;
  color: white;
  padding: 5px;
  align-items: center;
  border-radius: 0px 25px 25px 0px;
  width: 140px;
  left: -103px;
  transition: 0.1s;
  opacity: 1;
  z-index: 1001;
}

/* Hover effect on sidebar */
.leftsidehoverbar a:hover {
  left: 0px;
  opacity: 1;
  /* background-color: #4caf50; */
  background-color: orange;
  z-index: 1001;
}

/* float icons*/
.leftsidehoverbar i {
  float: right;
  transition: float 0.01s ease;
}

.leftsidehoverbar i:hover {
  float: left;
}

.img {
}

.leftside-icon {
  width: 30px;
  height: 30px;
  float: right;
  align-items: center;
}
/* defining position of each nav bar */
.article {
  top: 270px;
  width: 210px;
  height: 40px;
}

.Interview {
  top: 330px;
  width: 210px;
  height: 40px;
}

.Scripter {
  top: 390px;
  width: 210px;
  height: 40px;
}

.Suggested {
  top: 450px;
  width: 210px;
  height: 40px;
}

.Practice {
  top: 510px;
  width: 210px;
  height: 40px;
}

.hoverable-topic {
  margin-left: 55px;
}

/*ShareOption*/

.share-icons-container {
  display: inline-flex;
  align-items: center;
}

.share-icons-container > * {
  margin-right: 10px;
  transition: transform 0.2s ease-in-out;
}

.share-icons-container > *:hover {
  transform: scale(1.2);
}

.share-popup {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 130px;
  left: 50px;
  width: 30%;
  height: 50%;
  flex-direction: row;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  display: flex;
  flex-direction: row; /* Arrange icons horizontally */
  gap: 10px; /* Set the gap between icons */
  align-items: center;
  border: 1.5px solid blue;
}

.close {
  position: absolute;
  top: 100px;
  right: 50px;
  cursor: pointer;
  font-size: 30px;
}

.close:hover {
  color: green;
}
