.grey {
  color: rgb(40, 37, 37);
}
.blue {
  color: blue;
  font-size: 40px;
}
.container-form {
  width: 90vw;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem auto;
}
.container-left {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 1.2rem;
  padding: 2rem;
}

.form-btn {
  background-color: blue;
  color: white;
  padding: 15px 30px;
  border-radius: 30px;
  font-weight: 500;
  font-size: large;
  border: none;
  cursor: pointer;
  text-decoration: none;
  outline: none;
}
.form-btn:active {
  transform: translateX(4px);
}
.container-right {
  width: 50%;
  padding: 2rem;
  background-color: rgb(165, 254, 254);
  height: 100%;
  border-radius: 1rem;
}
.value-area {
  width: 90%;
  margin: 0 auto 1rem auto;
  padding: 1rem;
  border-radius: 1rem;
  border: none;
}
.text-area {
  width: 90%;
  margin: 0 auto 1rem auto;
  padding: 1rem;
  border-radius: 1rem;
  border: none;
  line-height: 3;
}
.red {
  color: red;
  font-style: italic;
  font-size: medium;
  margin: 0 auto 0.5rem auto;
}
.ml {
  margin: 0 2rem;
}
.point h1 {
  font-weight: bold;
  font-size: 20px;
  text-align: left;
  color: black;
  margin-left: 0%;
}
@media only screen and (max-width: 768px) {
  .container-form {
    flex-direction: column;
    align-items: center;
  }

  .container-left,
  .container-right {
    width: 100%;
  }

  .container-left {
    padding: 1rem;
  }
  .container-left h1 {
    width: 100%;
    margin-left: 0%;
  }
  .container-right {
    margin-top: 2rem;
  }
  .point h1 {
    font-weight: bold;
    font-size: 15px;
    text-align: left;
    color: black;
  }
  .form-btn {
    width: 100%;
  }

  .value-area,
  .text-area {
    width: calc(100%);
  }
}
