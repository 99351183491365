.fullBlog-second {
  margin-top: 40px;
}

.fullBlog-second,
.fullBlog-third {
  padding-left: 100px !important;
  padding-right: 100px !important;
  font-family: 'Spline Sans', sans-serif; 
}

.row .fullBlog {
  padding-left: 50px;
  padding-right: 50px;
}

.fullBlogTitle h3 {
  font-weight: 600; 
  font-size: 45px; 
  line-height: 1; 
  color: rgb(17, 24, 39); 
  margin-bottom: 0.5rem;
}

.fullBlogTitle p {
  font-weight: 600; 
  font-size: 14px; 
  line-height: 24px; 
  color: rgb(17, 24, 39); 
}

.fullBlogTitle .img-container {
  width: 100%;
  height:auto;
}

.fullBlogTitle .img-container img {
  width: 100%;
  border-radius: 2%;
  height: auto;
  display: block;
  object-fit: cover;
  margin:auto;
}

.fullBlogDescription {
  padding-top: 5px;
}

.fullBlogDescription section {
  padding-bottom: 20px;
}

.fullBlogDescription blockquote {
  text-align:center;
}


.fullBlogDescription h2 {
  font-weight: bolder;
  font-size: 2rem; 
  line-height: 2.5rem; 
  color: rgb(17, 24, 39); 
}

.fullBlogDescription h3 {
  font-weight: bolder;
  font-size: 1.5rem;
  line-height: 2.5rem;
  color: rgb(17, 24, 39);
  text-align: justify;
}

.fullBlogDescription p {
  font-size: 1rem; 
  font-weight: 400; 
  line-height: 1.5rem; 
  margin-top: 15px;
  text-align: justify;
  margin-left: 0px;
  width:100%;
}

.fullBlogDescription ul li {
  font-size: 1rem;
  font-weight: 400; 
  line-height: 1.5rem; 
  margin-top: 15px; text-align: justify;

}

.fullBlog-second .fullBlog-right {
  padding-left: 50px;
  padding-right: 50px;
}

.fullBlog-right h3 {
  font-weight: 700; 
  font-size: 20px;
  line-height: 24px; 
  color: rgb(43, 35, 32);
  border-bottom: 1px solid rgb(229, 231, 235);
  margin-bottom: 1rem; 
  padding-bottom: 15px;
}

.fullBlog-right .recent-post {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-bottom: 1rem; 
}

.fullBlog-right img {
  width: 30%;
  height: 30%;
  object-fit: cover;
  margin-bottom: 10px;
}

.fullBlog-right h4 {
  font-size: 1rem;
  font-weight: 500;
  color: rgb(17, 24, 39); 
  text-align: justify;
  display: -webkit-box;
  -webkit-box-orient: vertical; 
  overflow: hidden; 
  -webkit-line-clamp: 3; 
}

.fullBlog-right div:hover h4 {
  color: red; 
  cursor: pointer;
}

/* Full Blog Third Row */

.fullBlog-third h3 {
  font-size: 28px;
  font-weight: 700; 
}

.fullBlog-third h3,
.fullBlog-third p {
  margin-bottom: 0.5rem;
  text-align: center !important;
  color: rgb(17, 24, 39); 
}

.button-container .btn {
  border-radius: 30px;
  padding: 4px 10px !important;
  position: relative;
  font-size: 14px; 
  text-decoration: none;
  outline: none;
  line-height: 20px !important;
  background: linear-gradient(to right, #ef1c24, #1c0a7d 100%);
  cursor: pointer;
  color: #fff;
  margin: 5px;
  display: inline-block;
}

.button-container .btn .icon {
  padding-right: 5px;
  font-size: 20px;
}

.fullBlog-third {
  background: rgb(245, 245, 245); 
  padding: 20px;
  text-align: center;
}

.aders{
  width:90%;
  margin:auto;
  margin-top:20px;
}

@media only screen and (max-width: 767px) {
  .fullBlog-second,
  .fullBlog-third {
    padding-left: 2% !important;
    padding-right: 2% !important;
    margin-top: 20px;
  }
  .fullBlog-third h3 {
  font-size: 24px;
  font-weight: 700;
  }

  .row .fullBlog {
    padding-left: 20px;
    padding-right: 20px;
  }

  .fullBlogTitle h3 {
    font-size: 2.2rem; 
  }

  .fullBlogTitle .img-container {
    width: 100%;
  }

  .fullBlogDescription h2 {
    font-size: 1.4rem; 
  }

  .fullBlog-right {
    gap: 20px;
    display:none;
  }
  .aders {
  width: 95%;
  margin: auto;
  margin-top: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .fullBlog-second,
  .fullBlog-third {
    padding-left: 20px !important;
    padding-right: 20px !important;
    margin-top: 20px;
  }

  .row .fullBlog {
    padding-left: 20px;
    padding-right: 20px;
  }

  .fullBlogDescription blockquote {
  text-align: center;
  } 

  .fullBlog-right {
  gap: 20px;
  display: none;
  }

  .fullBlogTitle h3 {
    font-size: 2rem; 
  }

  .fullBlog-right img {
    width: 15%;
    height: 15%;
    object-fit: cover;
    margin-bottom: 10px;
  }

  .fullBlog-right .recent-post {
    margin-bottom: 5px;
  }

  .fullBlog-second .col-md-8,
  .fullBlog-second .col-md-4 {
    width: 100%;
  }
}